export const settings = [
  {
    id: 1,
    text: "Settings",
    action: "toggleSettings",
    extra: "",
  },
  { id: 2, text: "", onClick: "", extra: "hr" },
  { id: 3, text: "Sign out", action: "signOut", extra: "" },
];
